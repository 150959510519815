import React from "react"

import { Link, StaticQuery, graphql } from "gatsby"

const ArchiveList = () => (
  <StaticQuery
    query={graphql`
      query {
        allMicrocmsBlog {
          edges {
            node {
              year :publishDate(formatString: "YYYY")
            }
          }
        }
      }
    `}
    render={data => {
      const years = new Set();
      let arrayYears  = [];
      data.allMicrocmsBlog.edges.map(node => {
        years.add(node.node.year)
        arrayYears = Array.from(years);
        return arrayYears;
      })

      return (
        <div className="box is-space">
          <h3 className="text is-strong project-heading-3">アーカイブ</h3>
          <ul>
            {arrayYears.map(year => (
              <li key={year}>
                <Link
                  className="text is-sm"
                  to={`/blog/archive/${year}`}
                >
                  {year}
                </Link>
              </li>
            ))}
          </ul>
        </div>

      )
    }}
  />
)

export default ArchiveList