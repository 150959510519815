import React from "react"

import { Link, StaticQuery, graphql } from "gatsby"

const CategoryList = () => (
  <StaticQuery
    query={graphql`
      query {
        allMicrocmsCategory {
          edges {
            node {
              category
              categorySlug
            }
          }
        }
      }
    `}
    render={data => (
      <div className="box is-space">
        <h3 className="text is-strong project-heading-3">カテゴリ</h3>
        <ul>
          {data.allMicrocmsCategory.edges.map(({ node }) => (
            <li key={node.categorySlug}>
              <Link
                className="text is-sm"
                to={`/blog/category/${node.categorySlug}`}
              >
                {node.category}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )}
  />
)

export default CategoryList